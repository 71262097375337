import React from 'react';
import Select from 'antd/lib/select';
import { SORT_LATEST, SORT_OLDEST } from '@helpers/constants';

const defaultOptions = {
  sort: [
    { value: SORT_LATEST, label: SORT_LATEST },
    { value: SORT_OLDEST, label: SORT_OLDEST },
  ],
};

const getDefaultValue = (currentDefaultValue, options) => {
  if (currentDefaultValue) return currentDefaultValue;
  if (!Array.isArray(options)) return currentDefaultValue;
  return options[1].value;
};

const SortBy = ({ defaultValue, onChange, options = defaultOptions }) => {
  return (
    <div className="sort-by-container">
      <h3 className="sort-by-label">Sort by:</h3>
      <div className="sort-by">
        <Select
          dropdownClassName={'sort-by-dropdown'}
          defaultValue={getDefaultValue(defaultValue, options)}
          bordered={false}
          onChange={onChange}
          options={options?.sort}
        />
      </div>
    </div>
  );
};

export default SortBy;
