import React from 'react';
import Counter from './Counter';
import SortBy from './SortBy';

const Topbar = ({ count, defaultValue, onChange, options }) => {
  return (
    <div className="topbar-container">
      <div className="topbar">
        <Counter {...{ count }} />
        <SortBy {...{ defaultValue, onChange, options }} />
      </div>
    </div>
  );
};

export default Topbar;
