import React from 'react';

const NO_RESULT = 'No results';
const SINGLE_RESULT = '1 Result';

const Counter = ({ count = 0 }) => {
  const generateCounterTitle = () => {
    if (typeof count === 'string') return count;
    if (count === 0) return NO_RESULT;
    if (count === 1) return SINGLE_RESULT;
    return `${count} Results`;
  };

  const counterTitle = generateCounterTitle();
  return (
    <>
      <div className="counter-container">
        <h1 className="counter">{counterTitle}</h1>
      </div>
    </>
  );
};

export default Counter;
